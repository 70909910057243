
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import useNotify from "@/core/composables/notify";
import { IDVerification } from "@/assets/ts/_utils/models/IDVerification";
import { useStore } from "vuex";
import { User } from "@/store/models/user";
import { PERMS, hasPerms } from "@/core/helpers/permissions";
import useSubscriptions from "@/core/composables/useSubscriptions";
import { SubscriptionStatus } from "@/assets/ts/_utils/enums/subscription.enum";
import ApiService from "@/core/services/ApiService";
import Spinner from "@/components/Spinner.vue";
import { ADMIN } from "@/constants";

export default defineComponent({
  name: "users",
  components: {
    Datatable,
    Spinner,
  },
  setup() {
    const { toastErrorRaw, toastSuccess } = useNotify();
    const { fetchSubscriptions, loading: loadingSubscriptions } =
      useSubscriptions();

    const isAdmin = computed(
      () => store.getters.currentUser.user_type === ADMIN
    );

    const store = useStore();

    const tableHeader = computed(() => {
      const items = [
        {
          name: "Customer",
          key: "user",
        },
        {
          name: "Status",
          key: "status_display",
          sortable: true,
        },
        {
          name: "Billing",
          key: "autorenew",
        },
        {
          name: "Plan",
          key: "plan",
        },
        {
          name: "Created Date",
          key: "created",
        },
        {
          name: "Usage",
          key: "use",
        },
      ];

      if (!isAdmin.value) {
        items.push({
          name: "Actions",
          key: "edit",
        });
      }
      return items;
    });

    const tableData = ref<Array<IDVerification>>([]);
    const tableKey = ref(1);
    const selectedRow = ref<User | null>(null);
    const updating = ref(null);

    const fetchData = async (search?: string) => {
      try {
        tableData.value = [];

        const params: any = {};
        if (search) {
          params.search = search;
        }

        const resp = await fetchSubscriptions();
        tableData.value = resp;
        setCurrentPageBreadcrumbs("Subscriptions", ["Subscriptions List"]);
        tableKey.value = new Date().getTime();
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    const toggleAutoRenew = async (subscription) => {
      if (updating.value == subscription.id) {
        return toastErrorRaw(new Error("Currently updating subscription"));
      }

      try {
        updating.value = subscription.id;
        await ApiService.patch(`/subscriptions/${subscription.id}/`, {
          autorenew: !subscription.autorenew,
        } as any);
        await fetchData();
        toastSuccess({ text: "Autorenewal toggled successfully" });
      } catch (error) {
        toastErrorRaw(error);
      } finally {
        updating.value = null;
      }
    };

    const renew = async (subscription) => {
      if (updating.value == subscription.id) {
        return toastErrorRaw(new Error("Currently renewing subscription"));
      }

      try {
        updating.value = subscription.id;
        await ApiService.post(`/subscriptions/${subscription.id}/renew/`, {});
        await fetchData();
        toastSuccess({ text: "Subscription renewed successfully" });
      } catch (error) {
        toastErrorRaw(error);
      } finally {
        updating.value = null;
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      tableData,
      tableHeader,
      selectedRow,
      tableKey,
      toggleAutoRenew,
      authUser: store.getters.currentUser,
      SubscriptionStatus,
      updating,
      renew,
      loadingSubscriptions,
      isAdmin,
    };
  },
});
